// 提交时的空值判断
export function isEmptyValue(value) {
  return String(value) !== '0' && (!value || !String(value).trim() || String(value) === 'undefined' || String(value) === 'null')
}

export function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) return unescape(r[2])
  return null
}

export function getEnumLabelByValue(menus, value, valueKey = '', labelName = '') {
  for (let i = 0; i < menus.length; i++) {
    const item = menus[i]
    let itemValue = ''
    if (valueKey) {
      itemValue = item[valueKey]
    } else {
      itemValue = item.value
    }
    if (itemValue === value) {
      if (labelName) {
        return item[labelName]
      } else {
        return item.label
      }
    }
  }
}

/**
 * 深度克隆数据
 * @param obj
 * @returns
 */
export function deepClone(obj) {
  const result = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        // 有可能等于 null
        if (obj[key] === null) {
          result[key] = null
          continue
        }
        result[key] = deepClone(obj[key])
      } else {
        result[key] = obj[key]
      }
    }
  }
  return result
}
